<template>
  <!-- Reset Password Component -->
  <v-container>
    <v-card color="primary">
      <v-card-title>
        <pagetitle icon="mdi-lock-reset" title="Reset Password"></pagetitle>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field color="accent"
                data-cy="email"
                required
                label="Email"
                prepend-icon="mdi-email"
                v-model="email"
                :rules="emailRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn data-cy='reset-btn' outlined @click="onResetPassword" :disabled='!valid'
              ><v-icon left>mdi-lock-reset</v-icon>Reset Password</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn data-cy='login-btn' outlined @click="onLogin"
              ><v-icon left>mdi-login</v-icon>Login</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn data-cy='forgot-btn' outlined @click="onForgotUsername"
              ><v-icon left>mdi-account-question-outline</v-icon>Forgot
              Username</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn data-cy='register-btn' outlined @click="onRegister"
              ><v-icon left>mdi-account-plus-outline</v-icon>Register</v-btn
            >
            <v-spacer></v-spacer>
          </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import apiService from '@/_services/api-service'
import pagetitle from "../../components/controls/Title"
import store from "@/store"
import router from "@/router"
import breakpointMixin from "../../mixins/breakpointMixin"

export default {
  name: "Login",
  components: {
    pagetitle,
  },
  data: () => ({
    email: "",
    emailRules: [(v) => !!v || "Email is required"],
    valid: false,
  }),

  mixins: [breakpointMixin],

  methods: {
    onForgotUsername() {
      router.push("/account/forgot");
    },
    onLogin() {
      router.push("/account/login");
    },
    onRegister() {
      router.push("/account/register");
    },
    onResetPassword() {
      const url = 'account/reset/' + this.email
      apiService.post(url, {}, this.successCallback, this.errorCallback)
    },

    successCallback(response) {
      const notification = {
        title: "Reset Password",
        status: "success",
        messages: [response.data],
      };
      store.commit("setNotification", notification);
    },

    errorCallback(response) {
      const notification = {
        title: "Reset Password",
        status: "error",
        messages: [response.data],
      };
      store.commit("setNotification", notification);
    },

  },

  store,
  router,
};
</script>
<style lang="scss" scoped>
.v-card {
  margin-right: 15px;
}

.v-btn {
  margin-bottom: 20px;
  margin-left: 10px;
}
</style>
